@import "~@/styles/variables/variables.scss";























































































































































































































































































































































































@import '../runLayout.scss';
.serach-ops {
	margin-bottom: 14px;
}
